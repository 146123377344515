import React from "react";
import AdminVehicleEdit from "../../components/admins/vehicles/admin-vehicle-edit";

const AdminVehiclesEditPage = () => {
  return (
    <>
      <AdminVehicleEdit />
    </>
  );
};

export default AdminVehiclesEditPage;
