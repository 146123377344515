import React from 'react'
import AdminVehicleNew from '../../components/admins/vehicles/admin-vehicle-new'

const AdminVehicleNewPage = () => {
  return (
    <>
      <AdminVehicleNew/>
    </>
  )
}

export default AdminVehicleNewPage