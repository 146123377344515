import React from 'react'
import AdminVehicles from '../../components/admins/vehicles/admin-vehicles'

const AdminVehiclesPage = () => {
  return (
    <>
      <AdminVehicles/>
    </>
  )
}

export default AdminVehiclesPage