export const settings = {
  apiURL: "https://carrental-v2-backend.herokuapp.com",
  siteName: "AS CAR RENTAL",
  siteUrl: "ascarrent.com",
  email: "info@ascarrent.com",
  phone1: "+90 (543)-221-1414",
  phone2: "+90 (530)-977-5555",
  address: "Icerenkoy Mah, Istanbul, Turkey",
  mapEmbedUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12043.757644019306!2d28.76955814649656!3d41.004699325591524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac75e9efb6523%3A0xdb4f4c921f5b553f!2sASWA%20Group%20Consultancy%20Inc.!5e0!3m2!1sen!2str!4v1664993642312!5m2!1sen!2str",
};
