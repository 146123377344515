import React from 'react'
import Unauthorized from '../../components/common/unauthorized/unauthorized'

const UnauthorizedPage = () => {
  return (
    
    <>
      <Unauthorized/>
    </>
  )
}

export default UnauthorizedPage