import React from 'react'
import AdminReservations from '../../components/admins/reservations/admin-reservations'

const AdminReservationsPage = () => {
  return (
    <>
    <AdminReservations/>
    </>
  )
}

export default AdminReservationsPage