import React from "react";
import AdminContactMessageEdit from "../../components/admins/contact-messages/admin-contact-message-edit";

const AdminContactEditPage = () => {
  return (
    <>
      <AdminContactMessageEdit />
    </>
  );
};

export default AdminContactEditPage;
