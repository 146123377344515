import React from "react";
import AdminContactMessages from "../../components/admins/contact-messages/admin-contact-messages";

const AdminContactMessagesPage = () => {
  return (
    <>
      <AdminContactMessages />
    </>
  );
};

export default AdminContactMessagesPage;
