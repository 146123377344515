import React from "react";
import AdminUsers from "../../components/admins/users/admin-users";

const AdminUsersPage = () => {
  return (
    <>
      <AdminUsers />
    </>
  );
};

export default AdminUsersPage;
