import React from "react";
import Auth from "../../components/users/common/auth/auth";

const AuthPage = () => {
  return (
    <>
      <Auth />
    </>
  );
};

export default AuthPage;
